<template>
    <div class="hotTopic oepnX">
        <HeadBar titleName="官方博主"></HeadBar>
        <div class="contentBar">
            <PullRefresh v-if="!tIsLoading" :loading="tLoading" :refreshing="tRefreshing" :finished="tFinished" @onLoad="onLoad"
                         @onRefresh="onRefresh" :isNoData="tIsNoData" :error="tError" :is-higeht-max="true"
                         :adv-list="advList" :hasAdvPagination="true" className="HotTopic">
                <div class="topic">
                    <authorityBloggerList :topicList="recommendTopicList"></authorityBloggerList>
                </div>
            </PullRefresh>
            <Loading v-else />
        </div>
    </div>
</template>

<script>

import HeadBar from "@/components/HeadBar";
import {queryHotspotWonderList, queryMineCollectInfoList, queryRecommendUserList} from "@/api/community";
import Loading from "@/components/Loading";
import PullRefresh from "@/components/PullRefresh";
import authorityBloggerList from "./authorityBloggerList";

export default {
  name: "HotTopic",
    components:{
        HeadBar,Loading,PullRefresh,authorityBloggerList
    },
    data(){
      return {
          tIsLoading:true,
          gIsLoading:true,
          tFinished: false,
          gFinished: false,
          tIsNoData: false,
          gIsNoData: false,
          tRefreshing: false,
          gRefreshing: false,
          tLoading: true,
          gLoading: true,
          tError: false,
          gError: false,
          advList: [],
          tabActive : 1,
          tPageNumber: 1,
          gPageNumber: 1,
          tPageSize: 10 ,
          gPageSize: 10 ,
          recommendTopicList:[],    //推荐列表
          focusTopicList:[],        //关注列表
      }
    },mounted() {
      if(this.tabActive == 0){
          this.getMineCollectInfoList('refresh');
      }else{
          this.getHotspotWonderList('refresh');
      }
    },
    methods:{
        async getHotspotWonderList(type) {
            /**
             * 获取热门话题
             */
            let req = {
                pageNumber: this.tPageNumber,
                pageSize: this.tPageSize,
            }
            let res = await this.$Api(queryRecommendUserList, req);
            
            this.tLoading = false;
            this.tRefreshing = false;
            this.tIsLoading = false;

            try {
                if(res && res.code == 200){

                    if(type == "refresh"){
                        this.recommendTopicList = res.data.list;
                    }else {
                        this.recommendTopicList = this.recommendTopicList.concat(res.data.list);
                    }

                    if (this.pageNumber === 1 && this.recommendTopicList.length === 0) {
                        this.tIsNoData = true;
                    }

                    if (!res.data.hasNext || res.data.list.length < req.pageSize) {
                        this.tFinished = true;
                    }
                } else {
                    this.tError = true;
                }
            } catch (error) {
                this.tError = true;
                this.tLoading = false;
            }
        },
        async getMineCollectInfoList(type) {
            /**
             * 获取我关注的话题
             */
            let req = {
                pageNumber: this.gPageNumber,
                pageSize: this.gPageSize,
                type: 'tag',
                uid: this.$store.getters.userInfo.uid
            }
            let res = await this.$Api(queryMineCollectInfoList, req);
            
            this.gLoading = false;
            this.gRefreshing = false;
            this.gIsLoading = false;

            if (res && res.code == 200) {

                if (type == "refresh") {
                    this.focusTopicList = res.data.list;
                } else {
                    this.focusTopicList = this.focusTopicList.concat(res.data.list);
                }

                if (this.pageNumber === 1 && this.focusTopicList.length === 0) {
                    this.gIsNoData = true;
                }

                if (!res.data.hasNext || res.data.list.length < req.pageSize) {
                    this.gFinished = true;
                }
            }

        },onRefresh() {
            if(this.tabActive == 0){
                this.gPageNumber = 1;
                this.gRefreshing = true;
                this.gFinished = false;
                this.gIsNoData = false;
                this.getMineCollectInfoList('refresh');
            }else {
                this.tPageNumber = 1;
                this.tRefreshing = true;
                this.tFinished = false;
                this.tIsNoData = false;
                this.getHotspotWonderList('refresh');
            }
        },onLoad(){
            if(this.tabActive == 0){
                this.gLoading = true;
                if (this.gError) {
                    this.gError = false;
                } else {
                    this.gPageNumber++
                }
                this.getMineCollectInfoList();
            }else {
                this.tLoading = true;
                if (this.tError) {
                    this.tError = false;
                } else {
                    this.tPageNumber++
                }
                this.getHotspotWonderList();
            }
        },
        clickTag() {
            if(this.tabActive == 0){
                this.gIsLoading = true;
                this.gPageNumber = 1;
                this.gRefreshing = true;
                this.gFinished = false;
                this.gIsNoData = false;
                this.getMineCollectInfoList('refresh');
            }else {
                this.tIsLoading = true;
                this.tPageNumber = 1;
                this.tRefreshing = true;
                this.tFinished = false;
                this.tIsNoData = false;
                this.getHotspotWonderList('refresh');
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .hotTopic{
        
        /deep/ .van-tab{
            font-size: 12px;
            color: #666666;
        }
        
        /deep/ .van-tab--active{
            font-size: 14px !important;
            font-weight: bold;
            color: #333333 !important;
        }

        /deep/ .van-tabs__nav--line{
            width: 120px;
        }

        /deep/ .van-tabs--line .van-tabs__wrap{
            height: 51px;
        }

        /deep/ .van-tabs__line{
            bottom: 25px
        }
        
        .tabs{
            .tabContent{
                height: calc(100vh - 51px - 44px);
            }
        }
    }
</style>